<template>
  <v-container fluid id="regular-table">
    <v-form ref="form" v-model="is_valid" lazy-validation>
      <div class="me-6 mb-12">
        <h3 class="text-h3 ma-4">{{ $t("vat_settings") }}</h3>

        <v-flex>
          <div
            class="text-h4 d-flex justify-space-between"
            style="min-width: 500px"
          >
            <span class="text-h4 ma-4">{{ $t("VAT_list") }}</span>
            <v-combobox
              v-model="settings.vat_array"
              :items="[]"
              :rules="[]"
              hint="values are numeric ie: 19"
              label="add multiple VAT values"
              multiple
              persistent-hint
              small-chips
            ></v-combobox>
          </div>
        </v-flex>
      </div>
    </v-form>
    <div class="ma-4">
      <v-btn
        class="primary"
        @click="save()"
        :loading="isLoading"
        v-if="$admin.can('setting-price')"
        >save changes</v-btn
      >
      <v-btn color="indigo" text @click="cancel()">cancel</v-btn>
    </div>
  </v-container>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";
export default {
  mixins: [validationRules],
  data() {
    return {
      is_valid: false,
      isLoading: false,
    };
  },

  computed: {
    settings: {
      get() {
        return this.$store.state.settings.priceSettings;
      },
      set(value) {
        this.$store.commit("settings/SET_PRICE_SETTINGS", value);
      },
    },
  },

  methods: {
    async save() {
      this.validate();
      if (this.is_valid) {
        try {
          this.isLoading = true;
          await this.$store.dispatch(
            "settings/updatePriceSettings",
            this.settings
          );
          this.$store.dispatch(
            "alerts/success",
            this.$t("updated_successfully")
          );
          this.$router.push("/dashboard");
        } catch (error) {
          this.isLoading = false;
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        }
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    cancel() {
      this.$router.push("/dashboard");
    },

    async load() {
      await this.$store.dispatch("settings/getPriceSettings");
    },

    init() {
      this.load();
    },
  },
  created() {
    this.init();
  },
};
</script>
